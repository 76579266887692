<script setup lang="ts">
import { computed } from 'vue'
import type { FeedType } from '@fixtures/api/feed'
import type { LadderItems } from '@fixtures/api/ladder/types'
import Ladder from '@components/Home/Ladder.vue'
import PassportCard from './PassportCard.vue'

interface Props<T extends FeedType> {
  items: T[]
  ladder: LadderItems
}

const ladderPerItem = 6
const ladder = computed(() => props.ladder)

const props = defineProps<Props<FeedType>>()

const isLadderVisible = (i: number) => {
  return (
    i > 0 &&
    ladder.value.length * ladderPerItem >= i &&
    (i + 1) % ladderPerItem === 0
  )
}
</script>

<template>
  <div class="grid lg:grid-cols-2 gap-2 gap-y-4 lg:gap-y-8 auto-rows-fr">
    <template v-for="(feed, i) in props.items" :key="feed.id">
      <PassportCard
        class=""
        :address="feed.address"
        :id="feed.parentPassport.id"
        :profile="feed.parentProfile"
      />
      <Ladder
        v-if="isLadderVisible(i)"
        :ladder="[...[ladder[(i + 1) / ladderPerItem - 1]]]"
        :in-feed="true"
        class="lg:col-span-2"
      />
    </template>
  </div>
</template>
