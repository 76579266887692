<script setup lang="ts">
import { i18nFactory } from '@devprotocol/clubs-core'
import { LadderType, type LadderItems } from '@fixtures/api/ladder/types'
import { computed, onMounted, ref } from 'vue'

const props = defineProps<{ ladder: LadderItems; inFeed?: boolean }>()

const langs = ref<string[]>(['en'])
const ladder = computed(() => props.ladder.filter((x) => x))

onMounted(() => {
  langs.value = [...navigator.languages]
})
</script>

<template>
  <ul
    v-if="ladder.length > 0"
    class="relative grid gap-4"
    :class="[
      {
        'max-h-full justify-items-center h-full py-4': props.inFeed,
      },
    ]"
  >
    <li
      v-for="item in ladder"
      class="flex flex-col w-full"
      :class="{ 'items-center': props.inFeed }"
    >
      <p v-if="item.section" class="font-bold mb-1">
        {{ i18nFactory({ i: item.section.title })(langs)('i') }}
      </p>
      <a
        v-if="item.type === LadderType.Link"
        class="relative flex overflow-hidden rounded-2xl"
        :class="{
          'grow w-fit': props.inFeed,
          'aspect-[5/3]': !item.aspect,
        }"
        :href="i18nFactory({ i: item.link })(langs)('i')"
        :style="{
          aspectRatio:
            (props.inFeed && item.aspect?.feed
              ? item.aspect?.feed
              : item.aspect?.default) ?? item.aspect?.default,
        }"
      >
        <div
          role="presentation"
          class="absolute inset-0 bg-cover bg-no-repeat"
          :style="`background-image: url(${typeof item.image === 'string' ? item.image : props.inFeed && item.image.feed ? item.image.feed : item.image.default});`"
        ></div>
        <div
          class="relative flex w-full flex-col items-start justify-end px-3 py-2 font-bold text-white"
          :style="
            item.color
              ? `background-image: linear-gradient(0deg, ${item.color} 20%, transparent 60%);`
              : undefined
          "
        >
          <p v-if="item.title" class="text-lg">
            {{ i18nFactory({ i: item.title })(langs)('i') }}
          </p>
          <p v-if="item.description" class="text-sm">
            {{ i18nFactory({ i: item.description })(langs)('i') }}
          </p>
        </div>
      </a>
    </li>
  </ul>
</template>
