import type { ClubsI18nLocale } from '@devprotocol/clubs-core'

export enum LadderType {
  Link = 'link',
}

export type LadderLinkItem = Readonly<{
  type: LadderType.Link
  image: string | { default: string; feed?: string }
  link: Readonly<ClubsI18nLocale>
  color?: string
  aspect?: { default: string; feed?: string }
  section?: {
    title: Readonly<ClubsI18nLocale>
  }
  title?: Readonly<ClubsI18nLocale>
  description?: Readonly<ClubsI18nLocale>
}>

export type LadderItems = ReadonlyArray<LadderLinkItem>
