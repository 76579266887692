<script setup lang="ts">
import { ref, computed, onMounted, watch, useTemplateRef } from 'vue'
import type { FeedType } from '@fixtures/api/feed'
import Feed from '@components/Feed/Feed.vue'
import type { LadderItems } from '@fixtures/api/ladder/types'
import Ladder from '@components/Home/Ladder.vue'

interface Props<T extends FeedType> {
  items: T[]
  itemHeight: number
  buffer: number
  refresh?: string | number
  ladder: LadderItems
}

const ladderPerItem = 6
const DOWN = 'down'
const UP = 'up'

const props = defineProps<Props<FeedType>>()

const container = ref<HTMLElement | null>(null)
const scrollTop = ref(0)
// const scrollDirection = ref<typeof UP | typeof DOWN>(DOWN)
const refresh = computed(() => props.refresh)
const ladder = computed(() => props.ladder)

const totalHeight = computed(
  () => (props.items.length + ladder.value.length) * props.itemHeight,
)

const visibleCount = computed(() => {
  if (!container.value) return 0
  return Math.ceil(window.innerHeight / props.itemHeight) + props.buffer
})

const startIndex = computed(() => {
  return Math.max(
    0,
    Math.floor(scrollTop.value / props.itemHeight) - props.buffer,
  )
})

const endIndex = computed(() => {
  return Math.min(props.items.length, startIndex.value + visibleCount.value)
})

const visibleItems = computed(() => {
  return props.items
    .slice(startIndex.value, endIndex.value)
    .map((item, index) => ({
      ...item,
      offset: (startIndex.value + index) * props.itemHeight,
      index: startIndex.value + index,
    }))
})

const isLadderVisible = (i: number) => {
  return (
    i > 0 && ladder.value.length * ladderPerItem >= i && i % ladderPerItem === 0
  )
}

const onScroll = () => {
  if (container.value) {
    const top = Math.abs(container.value.getBoundingClientRect().top)
    // scrollDirection.value = scrollTop.value > top ? DOWN : UP
    scrollTop.value = top
  }
}

// スクロールイベントのスロットリング
let scrollTimeout: number | undefined
const throttledOnScroll = () => {
  if (scrollTimeout) {
    clearTimeout(scrollTimeout)
  }
  scrollTimeout = window.setTimeout(onScroll, 16)
}

onMounted(() => {
  container.value = window.document.body
  if (container.value) {
    scrollTop.value = container.value.scrollTop
  }
  window.document.addEventListener('scroll', throttledOnScroll)
})

watch([scrollTop, () => props.items], () => {
  if (container.value) {
    container.value.scrollTop = scrollTop.value
  }
})
watch(refresh, (_p) => {
  scrollTop.value = scrollTop.value + props.itemHeight
})
</script>

<template>
  <div class="relative" ref="container" :style="{ height: totalHeight + 'px' }">
    <div
      class="item relative"
      v-for="(feed, i) in visibleItems"
      :key="feed.id"
      :style="{
        marginTop: i === 0 ? `${feed.offset}px` : undefined,
        height: `${isLadderVisible(feed.index) ? props.itemHeight * 2 : props.itemHeight}px`,
      }"
    >
      <div
        class="grid max-h-full h-full"
        :class="{ 'grid-rows-2': isLadderVisible(feed.index) }"
      >
        <Feed :key="feed.id" v-bind="feed" />
        <Ladder
          v-if="isLadderVisible(feed.index)"
          :ladder="[...[ladder[feed.index / ladderPerItem - 1]]]"
          :in-feed="true"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.spacer {
  width: 100%;
}
.item {
  width: 100%;
  box-sizing: border-box;
}
</style>
