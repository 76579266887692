<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { getPassportOgImages } from '@fixtures/url/passports'
import { whenDefined } from '@devprotocol/util-ts'
import type { Profile } from '@pages/api/profile'

const props = defineProps<{
  address: string
  profile: Profile
  id: string
  class?: string
}>()

const ogpBase = ref<URL>()

const imgSrc = computed(() =>
  whenDefined(ogpBase.value, (url) =>
    getPassportOgImages({
      url,
      user: props.address,
      skinId: props.id,
      profile: props.profile,
    }),
  ),
)
const assetLink = computed(
  () => `/passport/${props.address}/${props.id ? props.id : ''}`,
)

onMounted(async () => {
  ogpBase.value = new URL(
    location.pathname,
    location.port ? 'https://clubs.place' : location.origin,
  )
})
</script>

<template>
  <a :href="assetLink" :class="props.class">
    <div
      class="rounded-md overflow-hidden aspect-[1200/630] bg-gray-500/50 relative"
    >
      <img loading="lazy" :src="imgSrc?.default" class="absorute inset-0" />
    </div>
  </a>
</template>
