<script setup lang="ts">
import type { FeedType } from '../../fixtures/api/feed'
import { computed, onMounted, ref, watch } from 'vue'
import { set } from 'es-cookie'
import { CookieKey } from '@constants/cookie'
import { Mode } from '@constants/feeds'
import VirtualScroll from './VirtualScroll.vue'
import ArrowPath from '@components/Icons/arrow-path.vue'
import Ladder from '@components/Home/Ladder.vue'
import type { LadderItems } from '@fixtures/api/ladder/types'
import PassportCards from './PassportCards.vue'
import { i18nFactory } from '@devprotocol/clubs-core'
import { Strings } from '@pages/passport/i18n'

const props = defineProps<{
  feeds: FeedType[]
  initialMode?: Mode
  langs: string[]
}>()

const mode = ref<Mode>(props.initialMode ?? Mode.Passports)
const unique = (data: FeedType[]): FeedType[] => {
  const pIDs = new Set<string>()
  return data
    .map((x) => {
      const id = x.parentPassport.id
      if (pIDs.has(id)) {
        return undefined
      }
      pIDs.add(id)
      return x
    })
    .filter((x) => x !== undefined)
}
const feedsByMode = ref<Record<Mode, FeedType[]>>({
  [Mode.Latest]: props.initialMode === Mode.Latest ? props.feeds : [],
  [Mode.Top]: props.initialMode === Mode.Top ? props.feeds : [],
  [Mode.Random6]: props.initialMode === Mode.Random6 ? props.feeds : [],
  [Mode.Passports]:
    props.initialMode === Mode.Passports ? unique(props.feeds) : [],
})
const switchingTo = ref<Mode>()
const items = computed(() => feedsByMode.value[mode.value])
const append = ref(false)
const loadingFeeds = ref([false, false])
const refreshToken = ref<string | number>()
const isModile = computed(() => itemHeight.value === 240)
const ladder = ref<LadderItems>()
const i18nBase = i18nFactory(Strings)
const i18n = ref(i18nBase(props.langs))

const changeMode = async (newmode: Mode) => {
  switchingTo.value = newmode
  if (feedsByMode.value[newmode].length > 0) {
    // Switch now, update later.
    mode.value = newmode
  }
  const feedSrc = new URL('/api/feed', location.origin)
  feedSrc.searchParams.set('tag', newmode)
  const res = await fetch(feedSrc)
  const json = (await res.json()) as {
    content: FeedType[] | null
    message: string
  }
  feedsByMode.value = {
    ...feedsByMode.value,
    [newmode]: append.value
      ? [
          ...feedsByMode.value[newmode],
          ...(newmode !== Mode.Passports
            ? (json.content ?? [])
            : unique(json.content ?? [])),
        ]
      : newmode !== Mode.Passports
        ? (json.content ?? [])
        : unique(json.content ?? []),
  }
  mode.value = newmode
  switchingTo.value = undefined
}

const handleLoadMore = async () => {
  append.value = true
  loadingFeeds.value = [true, false]
  const t = setTimeout(() => {
    loadingFeeds.value = [true, true]
  }, 500)
  await changeMode(mode.value)
  clearTimeout(t)
  loadingFeeds.value = [false, false]
  refreshToken.value = Math.random()
}

watch(mode, (mode_) => {
  set(CookieKey.DefaultFeed, mode_)
})

const itemHeight = ref(278)

const calcW = (w: number) => {
  return w > 768 ? 278 : 240
}

onMounted(async () => {
  itemHeight.value = calcW(window.innerWidth)
  window.addEventListener('resize', () => {
    itemHeight.value = calcW(window.innerWidth)
  })
  const res = await fetch('/api/ladder')
  const data = res.ok ? await res.json() : undefined
  ladder.value = data
})
</script>
<template>
  <div class="container mx-auto lg:px-4">
    <div
      class="lg:grid lg:grid-rows-[auto_auto] lg:grid-cols-[minmax(0px,270px)_640px_240px] gap-x-4"
    >
      <nav
        class="border-b lg:border-x border-black/10 row-start-1 row-span-1 col-start-2 col-span-1 w-full max-w-screen-sm mx-auto lg:mx-0"
      >
        <ul class="grid grid-cols-3 justify-stretch">
          <li>
            <button
              @click="changeMode(Mode.Passports)"
              class="w-full py-2 lg:py-4 hover:font-bold"
              :class="{
                'text-violet-700 font-bold': mode === Mode.Passports,
                'font-light': mode !== Mode.Passports && !switchingTo,
                'animate-pulse font-bold': switchingTo === Mode.Passports,
              }"
            >
              {{ i18n('Users') }}
            </button>
          </li>
          <li>
            <button
              @click="changeMode(Mode.Latest)"
              class="w-full py-2 lg:py-4 hover:font-bold"
              :class="{
                'text-violet-700 font-bold': mode === Mode.Latest,
                'font-light': mode !== Mode.Latest && !switchingTo,
                'animate-pulse font-bold': switchingTo === Mode.Latest,
              }"
            >
              {{ i18n('Latest') }}
            </button>
          </li>
          <li>
            <button
              @click="changeMode(Mode.Random6)"
              class="w-full py-2 lg:py-4 hover:font-bold"
              :class="{
                'text-violet-700 font-bold': mode === Mode.Random6,
                'font-light': mode !== Mode.Random6 && !switchingTo,
                'animate-pulse font-bold': switchingTo === Mode.Random6,
              }"
            >
              {{ i18n('Random') }}
            </button>
          </li>
        </ul>
      </nav>
      <div
        class="lg:border-x border-black/10 row-start-2 row-span-1 col-start-2 col-span-1 flex flex-col p-2 h-full w-full max-w-screen-sm mx-auto lg:mx-0"
      >
        <div
          class="flex flex-col gap-2 flex-grow h-full transition pb-24 lg:pb-0"
          :class="{ 'opacity-70': switchingTo && switchingTo !== mode }"
        >
          <VirtualScroll
            v-if="mode !== Mode.Passports"
            :items="items"
            :itemHeight="itemHeight"
            :buffer="1"
            :refresh="refreshToken"
            :ladder="isModile ? (ladder ?? []) : []"
          />
          <PassportCards
            v-if="mode === Mode.Passports"
            :items="items"
            :ladder="isModile ? (ladder ?? []) : []"
          />
          <button
            v-if="mode === Mode.Random6"
            @click="handleLoadMore"
            :disabled="loadingFeeds.includes(true)"
            class="place-self-center mt-6"
            title="Load more"
          >
            <span
              class="relative flex overflow-hidden items-center justify-center size-16 bg-violet-500 text-white rounded-full shadow"
            >
              <ArrowPath v-if="!loadingFeeds.includes(true)" class="size-12" />
              <div
                v-if="loadingFeeds.at(0)"
                class="size-6 animate-ping rounded-full bg-white opacity-75 absolute"
              />
              <div
                v-if="loadingFeeds.at(1)"
                class="size-6 animate-ping rounded-full bg-white opacity-75 absolute"
              />
            </span>
          </button>
        </div>
      </div>
      <div
        v-if="!isModile"
        class="row-start-1 row-span-2 col-start-3 col-span-1 py-4"
      >
        <Ladder :ladder="ladder ?? []" />
      </div>
    </div>
  </div>
</template>
